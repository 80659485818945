import React, { FC } from 'react'
import { AxiosError } from 'axios'
import { ListLineApi } from './line/LinesList'
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import { LineNumberExtended } from '../../models/LineModels'
import { Localizer } from '../../utils/localizer'
import { JdfLineNumber } from '@inprop/tt-ui-elements'
import { CheckedStatus } from '../../hooks/data/jdf/line/useFetchLinesWithSelection'

interface Props {
  lines: ListLineApi<Date>[]
  isLoadingLines: boolean
  loadingLinesError: AxiosError | undefined
  linesSelected: { [p: string]: boolean | undefined }
  checkedStatus: CheckedStatus
  onLineClick: (lineNumber: LineNumberExtended) => void
  onBulkToggleClick: () => void
  getLineIndex: (lineNumber: LineNumberExtended) => string
  noLinesInfoMessage: string
}

const JdfLinesSelector: FC<Props> = (props) => {
  const { palette } = useTheme()

  return (
    <>
      {props.lines.length !== 0 && (
        <Grid
          item
          xs={12}
          sx={{ background: palette.grey['100'], px: 2, py: 1, mb: 2, borderRadius: 1 }}
        >
          <FormControlLabel
            label={<Typography variant={'subtitle2'}>{Localizer.localize('All lines')}</Typography>}
            control={
              <Checkbox
                checked={props.checkedStatus === 'all'}
                indeterminate={props.checkedStatus === 'some'}
                onChange={props.onBulkToggleClick}
              />
            }
            sx={{ mb: 0 }}
          />
        </Grid>
      )}

      {props.isLoadingLines &&
        Array.from({ length: 24 }).map((_, index) => (
          <Grid item xs={6} md={3} lg={2} key={index} sx={{ pl: 2 }}>
            <Skeleton variant='text' sx={{ width: 1, my: 1 }} />
          </Grid>
        ))}

      {!props.isLoadingLines && !props.loadingLinesError && props.lines.length === 0 && (
        <Alert severity='info' sx={{ width: '100%', mb: 2 }}>
          {props.noLinesInfoMessage}
        </Alert>
      )}

      {!props.isLoadingLines &&
        props.lines.map((_) => (
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            key={`${_.lineNumber.lineNumber}${_.lineNumber.lineNumberExtension}`}
            sx={{ pl: 2 }}
          >
            <FormControlLabel
              label={
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    fontWeight: !!props.linesSelected[props.getLineIndex(_.lineNumber)] ? 'bold' : 'normal',
                  }}
                >
                  <JdfLineNumber lineId={_.lineNumber} />
                </Typography>
              }
              control={
                <Checkbox
                  checked={!!props.linesSelected[props.getLineIndex(_.lineNumber)]}
                  onChange={() => props.onLineClick(_.lineNumber)}
                />
              }
            />
          </Grid>
        ))}
    </>
  )
}

export default JdfLinesSelector
