import React, { FC } from 'react'

interface Props {
  protocolLog?: string
}

const ProtocolLog: FC<Props> = props => {
  if (!props.protocolLog) {
    return <></>
  }

  return (
    <div>
      {props.protocolLog.split('\n').map((_, index) => (
        <p key={index} className={'mb-0'}>
          {_}
        </p>
      ))}
    </div>
  )
}

export default ProtocolLog
