import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { apiUrls, appUrls } from '../utils/urls'
import { LoginDto, LoginViewModel, UserAuth, UserAuthDto } from '../models/AuthViewModels'
import { NotificationsContext, NotificationType } from './NotificationsContext'
import { ADD_NOTIFICATION } from './NotificationsReducer'
import { Localizer } from '../utils/localizer'
import { useHistory } from 'react-router-dom'
import { dtoToModel } from '../models/dtoToModel'
import { DateTime } from 'luxon'
import {
  clearAuthorizationHeader,
  setAuthorizationHeader,
  setInterceptorForUnauthorizedRequests,
} from '../utils/axiosUtils'

interface AuthContext {
  signInAsync: (email: string, password: string, rememberMe: boolean) => Promise<void>
  signOut: () => void
  isVerifyingUser: boolean
  isUserLoggedIn: boolean
  userAuth: UserAuth | undefined
  onUnauthorizedRequest: () => void
  accessToken: string
  hasUserCisCpCredentialsSaved: boolean
  onHasUserCisCpCredentialsSavedChanged: (newValue: boolean) => void
}

export const AuthContext = createContext<AuthContext>({
  signInAsync: () => Promise.resolve(),
  signOut: () => {},
  isVerifyingUser: false,
  isUserLoggedIn: false,
  userAuth: undefined,
  onUnauthorizedRequest: () => {},
  accessToken: '',
  hasUserCisCpCredentialsSaved: false,
  onHasUserCisCpCredentialsSavedChanged: (newValue: boolean) => {}
})

const ACCESS_TOKEN_COOKIE_KEY = 'CPEditEssentialCookie' // key intentionally does not contain anything like "access token"

const AuthContextProvider: FC = ({ children }) => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const history = useHistory()

  const [isVerifyingUser, setIsVerifyingUser] = useState(true)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [userAuth, setUserAuth] = useState<UserAuth>()
  const [hasUserCisCpCredentialsSaved, setHasUserCisCpCredentialsSaved] = useState(false)

  const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN_COOKIE_KEY])

  const singOutInternal = useCallback(() => {
      removeCookie(ACCESS_TOKEN_COOKIE_KEY, { path: '/' })
      clearAuthorizationHeader()
      setUserAuth(undefined)
      setIsUserLoggedIn(false)
      setHasUserCisCpCredentialsSaved(false)
    },
    [removeCookie],
  )

  const onUnauthorizedRequest = useCallback(
    () => {
      singOutInternal()
      history.push(appUrls.login)

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Warning,
          title: Localizer.localize('You don\'t have access to this page'),
          body: Localizer.localize(
            'You are not logged in or you were automatically logged out because of' +
            ' security reasons. Please, log in.',
          ),
          autoDismissAfterSeconds: 10,
        },
      })
    },
    [singOutInternal, history, notificationsDispatch],
  )

  useEffect(() => {
    setInterceptorForUnauthorizedRequests(onUnauthorizedRequest)
  }, [onUnauthorizedRequest])

  const accessToken = useMemo(
    () => cookies[ACCESS_TOKEN_COOKIE_KEY],
    [cookies[ACCESS_TOKEN_COOKIE_KEY]],
  )

  const setLoggedInAuthState = useCallback(
    (userAuth: UserAuth) => {
      setUserAuth(userAuth)
      setIsUserLoggedIn(true)
      setIsVerifyingUser(false)
      setHasUserCisCpCredentialsSaved(userAuth.hasCisCpCredentialsSaved)
    },
    [],
  )

  const loadUserAuthAsync = useCallback(
    async () => {
      const response = await axios.get<UserAuthDto>(apiUrls.auth.user)
      const userAuth = dtoToModel.userAuth(response.data)
      
      setLoggedInAuthState(userAuth)
    },
    [setLoggedInAuthState],
  )

  const checkAccessTokenAndLoadUserAuthWhenLoadingPage = useCallback(
    () => {
      setIsVerifyingUser(true)

      if (accessToken) {
        setAuthorizationHeader(accessToken)
        loadUserAuthAsync()

      } else {
        clearAuthorizationHeader()
        setIsVerifyingUser(false)
      }
    },
    [loadUserAuthAsync], // do not add 'accessToken' here, otherwise UserAuth data will be re-loaded after login
  )

  useEffect(() => {
    checkAccessTokenAndLoadUserAuthWhenLoadingPage()
  }, [checkAccessTokenAndLoadUserAuthWhenLoadingPage])

  const storeAccessToken = (accessToken: string, loginExpires: DateTime, rememberMe: boolean) => {
    // if (!rememberMe) { // TODO: implement rememberMe
    //   console.log('rememberMe is null or undefined or false')
    //   return true
    // }

    // TODO: this logic should be modified when implementing refresh tokens
    const options = { path: '/', expires: loginExpires.toJSDate() }
    setCookie(ACCESS_TOKEN_COOKIE_KEY, accessToken, options) // TODO: why exception is not displayed somehow somewhere?
  }

  const signInAsync = async (emailAddressOrUserName: string, password: string, rememberMe: boolean): Promise<void> => {
    setIsVerifyingUser(true)
    const loginData: LoginViewModel = { emailAddressOrUserName, password }

    const response = await axios.post<LoginDto>(apiUrls.auth.login, loginData)
    const loginDto = response.data
    const userAuth = dtoToModel.userAuth(loginDto.userAuth)

    storeAccessToken(loginDto.accessToken, userAuth.loginExpires, rememberMe)
    setAuthorizationHeader(loginDto.accessToken)
    setLoggedInAuthState(userAuth)
  }

  const signOut = (): void => {
    singOutInternal()
    history.push(appUrls.home)
  }

  const onHasUserCisCpCredentialsSavedChanged = (newValue: boolean) => {
    if (hasUserCisCpCredentialsSaved !== newValue) {
      setHasUserCisCpCredentialsSaved(newValue)
    }
  }
  
  return (
    <AuthContext.Provider
      value={{
        signInAsync,
        signOut,
        isUserLoggedIn,
        isVerifyingUser,
        userAuth,
        onUnauthorizedRequest,
        accessToken,
        hasUserCisCpCredentialsSaved,
        onHasUserCisCpCredentialsSavedChanged
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuthContext = () => useContext(AuthContext)

export { AuthContextProvider, useAuthContext }
