import { useContext } from 'react'
import { NotificationsContext, NotificationType } from '../contexts/NotificationsContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RegistrationAllowedEmail, RegistrationAllowedEmailViewModel } from '../models/AuthViewModels'
import {
  completeCisCpBatch,
  createCisCpCredentials,
  createRegistrationAllowedEmail,
  deleteCisCpCredentials,
  deleteRegistrationAllowedEmail,
  performActionWithCisCpBatch,
  updateCisCpCredentials,
  updateRegistrationAllowedEmail,
  uploadBatchToCisCp,
} from './apiFunctions'
import { ADD_NOTIFICATION } from '../contexts/NotificationsReducer'
import { Localizer } from '../utils/localizer'
import { queryKeys, queryKeysPrefixes } from './queryKeys'
import { CisCpCredentialsViewModel } from '../models/ProfileModels'
import { Action, CisCpBatch, UploadBatchToCisCpViewModel } from '../models/CisCpModels'
import { useNotifications } from '../hooks/useNotifications'

// region RegistrationAllowedEmails

export const useCreateRegistrationAllowedEmail = () => {
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<RegistrationAllowedEmailViewModel, void, RegistrationAllowedEmailViewModel>({
    mutationFn: async (registrationAllowedEmailViewModel) => {
      await createRegistrationAllowedEmail(registrationAllowedEmailViewModel)

      return registrationAllowedEmailViewModel
    },
    onSuccess: registrationAllowedEmailViewModel => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.registrationAllowedEmails })

      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localizeR('Email address {0} was successfully invited', registrationAllowedEmailViewModel.emailAddress),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while inviting the email address'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

export const useUpdateRegistrationAllowedEmail = () => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<RegistrationAllowedEmailViewModel, void, {
    registrationAllowedEmailId: number,
    registrationAllowedEmailViewModel: RegistrationAllowedEmailViewModel
  }, unknown>({
    mutationFn: async ({ registrationAllowedEmailId, registrationAllowedEmailViewModel }) => {
      await updateRegistrationAllowedEmail(registrationAllowedEmailId, registrationAllowedEmailViewModel)

      return registrationAllowedEmailViewModel
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.registrationAllowedEmails })

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('The invitation has been successfully edited'),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while editing the invitation'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

export const useDeleteRegistrationAllowedEmail = () => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<RegistrationAllowedEmail, void, RegistrationAllowedEmail>({
    mutationFn: async (registrationAllowedEmail) => {
      await deleteRegistrationAllowedEmail(registrationAllowedEmail.id)

      return registrationAllowedEmail
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.registrationAllowedEmails })

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('The invitation has been successfully deleted'),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while deleting the invitation'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

// endregion

// region CisCpCredentials

export const useCreateCisCpCredentials = () => {
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()
  
  return useMutation<CisCpCredentialsViewModel, void, CisCpCredentialsViewModel>({
    mutationFn: async (cisCpCredentialsViewModel) => {
      await createCisCpCredentials(cisCpCredentialsViewModel)
      
      return cisCpCredentialsViewModel
    },
    onSuccess: () => {
      void queryClient.resetQueries({ queryKey: queryKeys.cisCpCredentials })
      
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('CIS CP Credentials were successfully added'),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while adding CIS CP Credentials'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

export const useUpdateCisCpCredentials = () => {
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<CisCpCredentialsViewModel, void, CisCpCredentialsViewModel>({
    mutationFn: async (cisCpCredentialsViewModel) => {
      await updateCisCpCredentials(cisCpCredentialsViewModel)

      return cisCpCredentialsViewModel
    },
    onSuccess: () => {
      void queryClient.resetQueries({ queryKey: queryKeys.cisCpCredentials })

      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('CIS CP Credentials were successfully edited'),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while editing CIS CP Credentials'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

export const useDeleteCisCpCredentials = () => {
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<void, void, void, unknown>({
    mutationFn: async () => {
      await deleteCisCpCredentials()

      return
    },
    onSuccess: () => {
      void queryClient.resetQueries({ queryKey: queryKeys.cisCpCredentials })

      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('CIS CP Credentials were successfully deleted'),
          type: NotificationType.Success,
        }
      })
    },
    onError: () => {
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while deleting CIS CP Credentials'),
          type: NotificationType.Error,
        }
      })
    },
  })
}

// endregion

// region CisCpBatches

export const useUploadBatchToCisCp = () => {
  const queryClient = useQueryClient()
  
  return useMutation<CisCpBatch, void, UploadBatchToCisCpViewModel>({
    mutationFn: async (uploadBatchToCisCpViewModel) => {
      return await uploadBatchToCisCp(uploadBatchToCisCpViewModel)
    },
    // invalidate any queries that start with 'cisCpBatches' in their query key - i.e. all pages
    onSuccess: () => void queryClient.invalidateQueries({queryKey: [queryKeysPrefixes.cisCpBatches]}),
  })
}

export const usePerformActionWithCisCpBatch = () => {
  const { addSuccessNotification, addErrorNotification } = useNotifications()
  const queryClient = useQueryClient()

  return useMutation<void, void, {
    batchUserName: string,
    batchSequenceNumber: number,
    action: Action,
    note?: string
  }>({
    mutationFn: ({batchUserName, batchSequenceNumber, action, note}) => 
      performActionWithCisCpBatch(batchUserName, batchSequenceNumber, action, note),
    onSuccess: (data, variables) => {
      void queryClient.invalidateQueries({ queryKey: [queryKeysPrefixes.cisCpBatches] })
      
      const actionDescription = getActionSuccessDescription(variables.action)
      addSuccessNotification(
        Localizer.localizeR('The batch has been successfully {0}', actionDescription))
    },
    onError: (error, variables) => {
      const actionDescription = getActionErrorDescription(variables.action)
      addErrorNotification(
        Localizer.localizeR('Error occured while {0} the batch', actionDescription))
    },
  })
}

const getActionSuccessDescription = (action: Action) => {
  switch (action) {
    case Action.Delete: return Localizer.localize("deleted").toLowerCase()
    case Action.Submit: return Localizer.localize("submitted").toLowerCase()
    case Action.Approve: return Localizer.localize("approved").toLowerCase()
    case Action.Disapprove: return Localizer.localize("disapproved").toLowerCase()
    case Action.Forward: return Localizer.localize("forwarded").toLowerCase()
  }
}

const getActionErrorDescription = (action: Action) => {
  switch (action) {
    case Action.Delete: return Localizer.localize("deleting")
    case Action.Submit: return Localizer.localize("submitting")
    case Action.Approve: return Localizer.localize("approving")
    case Action.Disapprove: return Localizer.localize("disapproving")
    case Action.Forward: return Localizer.localize("forwarding")
  }
}

export const useCompleteCisCpBatch = () => {
  const { addSuccessNotification, addErrorNotification } = useNotifications()
  const queryClient = useQueryClient()
  
  return useMutation<void, void, { batchUserName: string, batchSequenceNumber: number }>({
    mutationFn: ({ batchUserName, batchSequenceNumber }) =>
      completeCisCpBatch(batchUserName, batchSequenceNumber),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeysPrefixes.cisCpBatches] })

      addSuccessNotification(Localizer.localizeR('The batch has been successfully {0}',
            Localizer.localize('completed')))
    },
    onError: () =>
      addErrorNotification(Localizer.localizeR('Error occured while {0} the batch',
        Localizer.localize('completing'))),
  })
}

// endregion
