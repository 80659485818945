import React, { FC, useMemo, useState } from 'react'
import { useCisCpBatches } from '../../data/queryFunctions'
import { CisCpBatch, CisCpStatus } from '../../models/CisCpModels'
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridRowParams,
} from '@mui/x-data-grid'
import { Localizer } from '../../utils/localizer'
import { getDateTimeForHumanWithoutLocale } from '../../utils/dateTimeUtils'
import { DateTime } from 'luxon'
import { Alert, Box, LabelDisplayedRowsArgs, Stack, TablePagination } from '@mui/material'
import { getMuiGridTranslations } from '../../utils/muiTranslations'
import { errorCodes } from '../../data/errorCodes'
import axios from 'axios'
import styles from './CisCpBatches.module.scss'
import JdfBatchCredentialsErrorAlert from './JdfBatchCredentialsErrorAlert'
import { capitalizeFirstLetter } from '../../utils/stringUtils'
import { Preloader } from '@inprop/tt-ui-elements'
import { ChangeStatusButton } from './ChangeStatusButton'
import { CompleteBatchButton } from './CompleteBatchButton'
import { RoutesCell } from './RoutesCell'

const CisCpBatchesList: FC = () => {
  const initialPageSize = 20
  const initialState = 1
  
  const [nameFilter, setNameFilter] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<CisCpStatus[]>([])
  const [pageSize, setPageSize] = useState<number>(initialPageSize)
  const [pageNumber, setPageNumber] = useState<number>(initialState)

  const {
    data: cisCpBatchesResponse,
    isLoading,
    isFetching,
    error
  } = useCisCpBatches(nameFilter, statusFilter, pageSize, pageNumber)

  const isReFetching = isFetching && !isLoading

  const columns: GridColDef<CisCpBatch>[] = useMemo(
    () => [
      { 
        field: 'userName',
        headerName: Localizer.localize('User'),
        flex: 1,
        sortable: false,
        filterable: false, // TODO: implement filtering by userName
      },
      {
        field: 'sequenceNumber',
        headerName: Localizer.localize('Sequence number'),
        type: 'number',
        align: 'right',
        sortable: false,
        filterable: false,
      },
      {
        field: 'routes',
        headerName: Localizer.localize('Routes'),
        type: 'number',
        align: 'right',
        flex: 0.5,
        sortable: false,
        filterable: false,
        renderCell: ({ row: { routes } }) => <RoutesCell routes={routes} />,
      } as GridColDef<CisCpBatch>,
      {
        field: 'description',
        headerName: Localizer.localize('Description'),
        flex: 3,
        sortable: false,
        filterable: false,
      },
      {
        field: 'uploadedAt',
        headerName: Localizer.localize('Uploaded at'),
        type: 'dateTime',
        flex: 1.5,
        valueFormatter: params => getDateTimeForHumanWithoutLocale(params.value),
        sortable: false,
        filterable: false,
      } as GridColDef<CisCpBatch, DateTime, string>,
      {
        field: 'batchStatus',
        headerName: Localizer.localize('Status'),
        flex: 1,
        valueGetter: params => capitalizeFirstLetter(Localizer.localize(params.value)),
        sortable: false,
        filterable: false, // TODO: implement filtering by status
      } as GridColDef<CisCpBatch, string>,
      {
        field: 'actions',
        headerName: Localizer.localize('Actions'),
        sortable: false,
        filterable: false,
        width: 120,
        cellClassName: styles.outlineNone,
        renderCell: ({ row: cisCpBatch }) => (
          <Stack direction={'row'}>
            {cisCpBatch.actions.map((action, index) =>
              <ChangeStatusButton key={index} cisCpBatch={cisCpBatch} action={action} />,
            )}

            {(cisCpBatch.batchStatus === CisCpStatus.Uploaded
              || cisCpBatch.batchStatus === CisCpStatus.Submitted) &&
              <CompleteBatchButton cisCpBatch={cisCpBatch} />
            }
          </Stack>
        ),
      } as GridColDef<CisCpBatch>,
    ],
    [],
  )
  
  const gridTranslations = useMemo(() => getMuiGridTranslations(), [])

  if (error && axios.isAxiosError(error) && error.response) {
    if (
      error.response.data?.code === errorCodes.CisCp_NoCredentialsCreated ||
      error.response.data?.code === errorCodes.CisCp_WrongCredentials
    ) {
      return (
        <div className={'row pb-4'}>
          <div className={'col-12 d-flex'}>
            <JdfBatchCredentialsErrorAlert
              error={error}
              noCredentialsCreatedWarningMessage={Localizer.localize(
                'To display and manage JDF batches You have to provide CIS CP credentials.'
              )}
              wrongCredentialsCWarningMessage={Localizer.localize(
                'Loading of JDF batches failed because provided CIS CP credentials are wrong.'
              )}
            />
          </div>
        </div>
      )
    }
    
    // others errors - 500 and other 400
    // TODO: create some global method or component
    console.error('Error when loading list of JDF batches: ', error)

    return (
      <div className={'row pb-4'}>
        <div className={'col-12 d-flex'}>

          <Alert
            severity={'error'}
            sx={{ mb: 3 }}
            className={'w-100'}
          >
            <div>
              {Localizer.localize('Could not load JDF batches')}
            </div>
              
            {/*<Button*/}
            {/*  variant={'contained'}*/}
            {/*  color={'secondary'}*/}
            {/*  className={'mt-2'}*/}
            {/*>*/}
            {/*  {Localizer.localize('Show error')}*/}
            {/*</Button>*/}
          </Alert>

        </div>
      </div>
    )
  }
  
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(initialState)
  }

  const getLabelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
    const toCount = (cisCpBatchesResponse && !cisCpBatchesResponse.isNextPage)
      ? ((pageSize * (pageNumber - 1)) + (cisCpBatchesResponse.result?.length))
      : paginationInfo.to

    return `${Localizer.localize('Page')} ${paginationInfo.page + 1}, ${Localizer.localize('records')}: ${paginationInfo.from}-${toCount}`
  }
  
  const getRowClassName = (params: GridRowParams<CisCpBatch>) => {
    switch (params.row.batchStatus) {
      case CisCpStatus.Approved:
        return styles.cpEditStatusApproved
      case CisCpStatus.Disapproved: 
        return styles.cpEditStatusDisapproved
      case CisCpStatus.Forwarded: 
        return styles.cpEditStatusForwarded
      case CisCpStatus.Processed:
        return styles.cpEditStatusProcessed
      case CisCpStatus.Error: 
        return styles.cpEditStatusError
      default:
        return ''
    }
  }
  
  return (
    <div className={'row pb-4 flex-grow-1'}>
      <div className={'col-12 d-flex'}>

        <Box className={'w-100'}>
          <DataGrid<CisCpBatch>
            columns={columns}
            rows={cisCpBatchesResponse?.result ?? []}
            loading={isLoading}
            localeText={gridTranslations}
            disableSelectionOnClick
            getRowId={row => `${row.userName}/${row.sequenceNumber}`}
            paginationMode={'server'}
            rowCount={-1}
            components={{
              Pagination: () =>
                <TablePagination
                  component='div'
                  page={pageNumber - 1}
                  onPageChange={(event, newPage) => setPageNumber(newPage + 1)}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 30]}
                  count={-1}
                  labelRowsPerPage={Localizer.localize('Rows per page') + ':'}
                  labelDisplayedRows={getLabelDisplayedRows}
                  backIconButtonProps={{ title: Localizer.localize('Go to previous page') }}
                  nextIconButtonProps={{
                    title: Localizer.localize('Go to next page'),
                    disabled: !cisCpBatchesResponse?.isNextPage,
                  }}
                />,
              Footer: () =>
                <GridFooterContainer>
                  <div style={{paddingLeft: 10}}>
                    {isReFetching &&
                      <Stack direction={'row'}>
                        <span style={{paddingRight: 10}}>{Localizer.localize('Reloading CIS CP batches...')}</span>
                        <Preloader overlay={false}/>
                      </Stack>
                    }
                  </div>
                  
                  <GridFooter sx={{
                    border: 'none', // to delete double (thicker) border
                  }} />
                </GridFooterContainer>
            }}
            getRowClassName={getRowClassName}
          />
        </Box>

      </div>
    </div>
  )
}

export default CisCpBatchesList
