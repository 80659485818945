import axios from 'axios'
import {
  RegistrationAllowedEmailDto,
  RegistrationAllowedEmailViewModel,
  UserExcerptDto,
} from '../models/AuthViewModels'
import { apiUrls } from '../utils/urls'
import { dtoToModel } from '../models/dtoToModel'
import { CisCpCredentialsDto, CisCpCredentialsViewModel } from '../models/ProfileModels'
import { Action, CisCpBatchDto, CisCpStatus, UploadBatchToCisCpViewModel } from '../models/CisCpModels'
import { CursorPaginatedResponse } from '../models/GeneralModels'

// region Users

export const getAllUsers = () =>
  axios.get<UserExcerptDto[]>(apiUrls.users.getAll)
    .then(response => response.data.map(dtoToModel.userExcerpt))

// endregion

// region RegistrationAllowedEmails 

export const getAllRegistrationAllowedEmails = () =>
  axios.get<RegistrationAllowedEmailDto[]>(apiUrls.registrationAllowedEmails.getAll)
    .then(response => response.data.map(dtoToModel.registrationAllowedEmail))

export const createRegistrationAllowedEmail = (registrationAllowedEmailViewModel: RegistrationAllowedEmailViewModel) =>
  axios.post<number>(apiUrls.registrationAllowedEmails.base, registrationAllowedEmailViewModel)

export const updateRegistrationAllowedEmail =
  (registrationAllowedEmailId: number, registrationAllowedEmailViewModel: RegistrationAllowedEmailViewModel) =>
    axios.patch<void>(apiUrls.registrationAllowedEmails.updateAndDelete(registrationAllowedEmailId), registrationAllowedEmailViewModel)

export const deleteRegistrationAllowedEmail = (registrationAllowedEmailId: number) =>
  axios.delete<void>(apiUrls.registrationAllowedEmails.updateAndDelete(registrationAllowedEmailId))

// endregion

// region CisCpCredentials

export const getCisCpCredentials = () =>
  axios.get<CisCpCredentialsDto | undefined>(apiUrls.cisCpCredentials.base)
    .then(response => dtoToModel.cisCpCredentials(response.data))

export const createCisCpCredentials = (cisCpCredentialsViewModel: CisCpCredentialsViewModel) =>
  axios.post<number>(apiUrls.cisCpCredentials.base, cisCpCredentialsViewModel)

export const updateCisCpCredentials = (cisCpCredentialsViewModel: CisCpCredentialsViewModel) => 
  axios.put<void>(apiUrls.cisCpCredentials.base, cisCpCredentialsViewModel)

export const deleteCisCpCredentials = () =>
  axios.delete<void>(apiUrls.cisCpCredentials.base)

// endregion

// region CisCpBatches

export const getCisCpBranches = (nameFilter: string, statusFilter: CisCpStatus[], pageSize: number, pageNumber: number) =>
  axios.get<CursorPaginatedResponse<CisCpBatchDto[]>>(apiUrls.cisCpBatches.base, {
    params: {
      nameFilter: nameFilter,
      statusFilter: statusFilter,
      pageSize: pageSize,
      pageNumber: pageNumber,
    },
  }).then(response => dtoToModel.cursorPaginatedResponseArray(response.data, dtoToModel.cisCpBatch))

export const uploadBatchToCisCp = (uploadBatchToCisCpApiModel: UploadBatchToCisCpViewModel) =>
  axios.post<CisCpBatchDto>(apiUrls.cisCpBatches.base, uploadBatchToCisCpApiModel)
    .then(response => dtoToModel.cisCpBatch(response.data))

export const performActionWithCisCpBatch = (batchUserName: string, batchSequenceNumber: number, action: Action, note?: string) =>
  axios.patch<void>(apiUrls.cisCpBatches.performAction(batchUserName, batchSequenceNumber), undefined, {
    params: {
      action: action,
      note: note,
    },
  }).then(response => response.data)

export const completeCisCpBatch = (batchUserName: string, batchSequenceNumber: number) =>
  axios.patch<void>(apiUrls.cisCpBatches.complete(batchUserName, batchSequenceNumber))
    .then(response => response.data)

// endregion
