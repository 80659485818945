import React, { FC, useContext, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuthContext } from '../../../../contexts/AuthContext'
import { appUrls } from '../../../../utils/urls'
import { Preloader } from '@inprop/tt-ui-elements'
import { Card } from 'react-bootstrap'
import { Localizer } from '../../../../utils/localizer'
import { Alert } from '@mui/material'
import { Role } from '../../../../models/AuthViewModels'
import { NotificationsContext, NotificationType } from '../../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'

interface PrivateRouteProps extends RouteProps {
  // Restrict access to this page only for certain roles. 
  // List of roles which are authorized to access this page, user must have one of these roles. 
  // If not set or set to 'undefined', that means that user can have any role (but must be logged  
  // in).
  roles?: Role[]
}

// Private route represents a route / page that is accessible only to logged in user. 
// It is possible to restrict access only to user with defined role(s) via 'roles' prop. 
const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { isUserLoggedIn, isVerifyingUser, userAuth } = useAuthContext()
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  useEffect(() => {
    if (!isVerifyingUser && !isUserLoggedIn) {
      // case B 1/2: user not logged in => show info growl message (here) and redirect to login screen (lower)
      // posting a notification must be inside useEffect, and useEffect must be before first condition which renders content
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Neutral,
          title: Localizer.localize('You are not logged in'),
          body: Localizer.localize(
            'You are not logged in, or you login have expired. You have to be logged in to see this page - please, log in',
          ),
          autoDismissAfterSeconds: 10,
        },
      })
    }
  }, [isVerifyingUser, isUserLoggedIn])
  
  if (isVerifyingUser) {
    //  case A: user verification in progress => display loading animation
    return (
      <div className={'py-5 w-100 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }
  
  if (!isUserLoggedIn){
    // case B 2/2: user not logged in => show info growl message (in useEffect) and redirect to login screen (here)
    return <Redirect to={appUrls.login} />
  }

  if (userAuth && props.roles && !props.roles.includes(userAuth.role)) {
    // case C: user logged in, but does not have role needed to see the page => display error page
    return (
      <div className={'row py-4'}>
        <div className={'col-12 col-md-8 offset-md-2 d-flex align-items-center'}>
          <Card className={'w-100'}>
            <Card.Header>
              <h5 className={'my-0'}>{Localizer.localize('Permissions error')}</h5>
            </Card.Header>
            <Card.Body>
              <Alert severity={'error'}
                     sx={{ mb: 3 }}>
                {Localizer.localize('You do not have rights to see this page')}
              </Alert>
            </Card.Body>
          </Card>
        </div>
      </div>
    )
  }

  // case D: user logged in and has role needed to see the page => display the page
  return (
    <Route {...props}>{props.children}</Route>
  )
}

export default PrivateRoute
