import React, { FC } from 'react'
import ConfirmDialog, { CustomConfirmDialogProps } from '../general/ConfirmDialog'
import { Localizer } from '../../utils/localizer'
import { Typography } from '@mui/material'
import { usePerformActionWithCisCpBatch } from '../../data/mutations'
import { Action, CisCpBatch} from '../../models/CisCpModels'
import { getActionIconAndDescription } from './ChangeStatusButton'
import { getBatchIdentification } from '../../utils/cisCpBatchesUtils'

interface Props extends CustomConfirmDialogProps {
  cisCpBatch: CisCpBatch
  action: Action
}

export const PerformActionWithCisCpBatchConfirmDialog: FC<Props> = props => {

  const {
    mutateAsync: performActionWithCisCpBatchAsync,
    isLoading,
  } = usePerformActionWithCisCpBatch()

  const handleConfirm = async () => {
    await performActionWithCisCpBatchAsync({
      batchUserName: props.cisCpBatch.userName,
      batchSequenceNumber: props.cisCpBatch.sequenceNumber,
      action: props.action,
    })
    
    props.handleClose()
  }
  
  const { icon, localizedDescription } = getActionIconAndDescription(props.action)

  return (
    <ConfirmDialog
      open={props.open}
      onConfirm={handleConfirm}
      onClose={props.handleClose}
      confirmButtonText={localizedDescription}
      confirmButtonStartIcon={icon}
      isLoading={isLoading}
      content={
        <Typography>
          {Localizer.localizeR('Do you want to {0} the batch {1}', 
            localizedDescription.toLowerCase(), getBatchIdentification(props.cisCpBatch))}?
        </Typography>
      }
    />
  )
}
