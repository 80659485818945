import React, { FC, MouseEvent, ReactNode } from 'react'
import { Box, Dialog, DialogProps, Stack, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Button from '@mui/material/Button'
import { Localizer } from '../../utils/localizer'
import LoadingButton from '@mui/lab/LoadingButton'

// these Props are used by component which wraps over this 'ConfirmDialog' component
export interface CustomConfirmDialogProps {
  open: boolean
  handleClose: () => void
}

// these Props are used by this 'ConfirmDialog' component
interface Props extends DialogProps {
  title?: string
  content?: ReactNode
  confirmButtonText?: string
  onConfirm: () => void
  isLoading?: boolean
  confirmButtonStartIcon?: ReactNode
  confirmButtonEndIcon?: ReactNode
}

const ConfirmDialog: FC<Props> = ({
  title,
  content,
  confirmButtonText,
  onConfirm,
  isLoading,
  confirmButtonStartIcon,
  confirmButtonEndIcon,
  ...dialogProps
}) => {

  const onCancel = (event: MouseEvent<HTMLButtonElement>) => {
    if (dialogProps.onClose) {
      dialogProps.onClose(event, 'escapeKeyDown')
    }
  }

  return (
    <Dialog {...dialogProps}>
      <Stack sx={{ p: 4, minWidth: '300px' }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ mb: 4 }}
        >
          <Typography fontWeight={'bold'}>
            {title ?? Localizer.localize('Confirm')}
          </Typography>
          <IconButton onClick={event => onCancel(event)}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>

        {content && <Box sx={{ mb: 3 }}>{content}</Box>}

        <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
          <LoadingButton
            variant={'contained'}
            onClick={onConfirm}
            loading={isLoading}
            startIcon={confirmButtonStartIcon}
            endIcon={confirmButtonEndIcon}
          >
            {confirmButtonText ?? Localizer.localize('Confirm')}
          </LoadingButton>
          <Button
            onClick={event => onCancel(event)}
            color={'secondary'}
          >
            {Localizer.localize('Cancel')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ConfirmDialog
