import React, { FC } from 'react'
import { Tooltip } from '@mui/material'
import { CisCpRouteExcerpt } from '../../models/CisCpModels'

interface Props {
  routes: CisCpRouteExcerpt[]
}

const maxRoutesInTooltip = 20

export const RoutesCell: FC<Props> = props =>
  (
    <Tooltip title={
      props.routes.length > 0
        ? (
          <>
            {props.routes.slice(0, maxRoutesInTooltip).map((route, index) =>
              <div key={index}>{route.routeNumber}</div>,
            )}
            {(props.routes.length > maxRoutesInTooltip) && (
              <div style={{ textAlign: 'center' }}>...</div>
            )}
          </>
        )
        : ''
    }>
      <div>
        {props.routes.length}
      </div>
    </Tooltip>
  )
