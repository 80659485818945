import React, { FC } from 'react'
import { StandardDialogProps } from '../../utils/DialogUtils'
import { CisCpBatch } from '../../models/CisCpModels'
import ConfirmDialog from '../general/ConfirmDialog'
import { Localizer } from '../../utils/localizer'
import { useCompleteCisCpBatch } from '../../data/mutations'
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut'
import { Typography } from '@mui/material'
import { getBatchIdentification } from '../../utils/cisCpBatchesUtils'

export const CompleteBatchConfirmDialog: FC<StandardDialogProps<CisCpBatch>> = props => {

  const {
    mutateAsync: completeBatchAsync,
    isLoading
  } = useCompleteCisCpBatch()

  const handleConfirm = async () => {
    await completeBatchAsync({ 
      batchUserName: props.model.userName,
      batchSequenceNumber: props.model.sequenceNumber
    })

    props.handleClose()
  }
  
  return (
    <ConfirmDialog
      open={props.open}
      onConfirm={handleConfirm}
      onClose={props.handleClose}
      confirmButtonText={Localizer.localize('Complete')}
      confirmButtonStartIcon={<SwitchAccessShortcutIcon />}
      isLoading={isLoading}
      content={
        <Typography>
          {Localizer.localizeR('Do you want to {0} the batch {1}',
            Localizer.localize('Complete').toLowerCase(), getBatchIdentification(props.model))}? 
        </Typography>
      }
    />
  )
}
