import React, { FC } from 'react'
import CisCpCredentials from '../profile/cisCpCredentials/CisCpCredentials'

const ProfilePage: FC = () => {
  
  return (
    <div className={'row py-4'}>
      {/*<div className={'col-12 col-md-6 col-xl-5 d-flex'}>*/}
      {/*  <p>Here will be UserDetail form</p>*/}
      {/*</div>*/}
      
      <div className={'col-12 col-md-6 col-xl-5 offset-xl-1 d-flex'}>
        <CisCpCredentials />
      </div>
    </div>
  )
}

export default ProfilePage
