import React, { FC } from 'react'
import { AxiosError } from 'axios'
import { Alert, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { appUrls } from '../../utils/urls'
import { errorCodes } from '../../data/errorCodes'
import { Localizer } from '../../utils/localizer'

interface Props {
  error: AxiosError
  noCredentialsCreatedWarningMessage: string
  wrongCredentialsCWarningMessage: string
  onGoToProfile?: () => void
}
const JdfBatchCredentialsErrorAlert:FC<Props> = (props) => {
  const history = useHistory()
  
  const goToProfile = () => {
    history.push(appUrls.profile)
    props.onGoToProfile?.()
  }
  
  const alertContent = (props.error.response?.data?.code === errorCodes.CisCp_NoCredentialsCreated)
    ? (
      <div>
        {props.noCredentialsCreatedWarningMessage}{' '}
        {Localizer.localize('You can add them on Profile page.')}
      </div>
    ) : (
      <div>
        <p className={'mb-1'}><b>{Localizer.localize('Wrong CIS CP credentials')}</b></p>
        {props.wrongCredentialsCWarningMessage}{' '}
        {Localizer.localize('You can edit them on Profile page.')}
      </div>
    )

  return (
    <Alert
      severity={'warning'}
      sx={{ mb: 3 }}
      className={'w-100'}
    >
      {alertContent}

      <Button
        variant={'contained'}
        color={'secondary'}
        className={'mt-2'}
        onClick={goToProfile}
      >
        {Localizer.localize('Go to Profile')}
      </Button>
    </Alert>
  )
}

export default JdfBatchCredentialsErrorAlert
