
export const errorCodes = {
  emailAddressNotAllowedToRegister: 'EmailAddressNotAllowedToRegister',
  
  // region CIS CP 
  CisCp_NoCredentialsCreated: 'CisCp_NoCredentialsCreated',
  CisCp_WrongCredentials: 'CisCp_WrongCredentials',

  CisCp_BatchImportedWithErrors: 'CisCp_BatchImportedWithErrors',
  CisCp_BatchImportFinishedInUnexpectedState: 'CisCp_BatchImportFinishedInUnexpectedState',
  
  // endregion
}
