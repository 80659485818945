import {
  RegistrationAllowedEmail,
  RegistrationAllowedEmailDto,
  UserAuth,
  UserAuthDto,
  UserExcerpt,
  UserExcerptDto,
} from './AuthViewModels'
import { parseUTCDateTimeToLocal } from '../utils/dateTimeUtils'
import { CisCpCredentials, CisCpCredentialsDto } from './ProfileModels'
import { CisCpBatch, CisCpBatchDto, CisCpRouteExcerpt, CisCpRouteExcerptDto } from './CisCpModels'
import { DateTime } from 'luxon'
import { CursorPaginatedResponse } from './GeneralModels'

export const dtoToModel = {
  userAuth: (dto: UserAuthDto): UserAuth => ({
    ...dto,
    loginExpires: parseUTCDateTimeToLocal(dto.loginExpires),
  }),
  userExcerpt: (dto: UserExcerptDto): UserExcerpt => ({
    ...dto,
    registeredAt: parseUTCDateTimeToLocal(dto.registeredAt),
    verifiedAt: dto.verifiedAt ? parseUTCDateTimeToLocal(dto.verifiedAt) : null,
  }),
  registrationAllowedEmail: (dto: RegistrationAllowedEmailDto): RegistrationAllowedEmail => ({
    ...dto,
    invitationDateTime: parseUTCDateTimeToLocal(dto.invitationDateTime),
    addedByUser: dtoToModel.userExcerpt(dto.addedByUser),
  }),
  cisCpCredentials: (dto: CisCpCredentialsDto | undefined): CisCpCredentials | undefined => dto,
  cisCpBatch: (dto: CisCpBatchDto): CisCpBatch => ({
    ...dto,
    uploadedAt: DateTime.fromISO(dto.uploadedAt),
    routes: dto.routes.map(cisCpRouteExcerptDto => dtoToModel.cisCpRouteExcerpt(cisCpRouteExcerptDto)),
  }),
  cisCpRouteExcerpt: (dto: CisCpRouteExcerptDto): CisCpRouteExcerpt => dto,

  cursorPaginatedResponse: <T, U>(response: CursorPaginatedResponse<T>, mapFn: (value: T) => U): CursorPaginatedResponse<U> => ({
    ...response,
    result: mapFn(response.result),
  }),
  cursorPaginatedResponseArray: <T, U>(response: CursorPaginatedResponse<T[]>, mapFn: (value: T) => U): CursorPaginatedResponse<U[]> => ({
    ...response,
    result: response.result.map(mapFn),
  }),
}
