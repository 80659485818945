import { JdfLineDetailsPageRouteParams } from '../components/pages/JdfLineDetailsPage'
import { JdfRouteParams } from '../components/layout/navigation/routes/JdfRouteWrapper'
import { ApiEndpointsState } from '@inprop/tt-maps/dist/Contexts/ApiEndpointsContext'
import { MapPageRouteParams } from '../components/pages/MapPage'
import { JdfConnectionEditPageParams } from '../components/pages/JdfConnectionEditPage'
import { JdfLineEditPageRouteParams } from '../components/pages/JdfLineEditPage'
import { LineNumberExtended } from '../models/LineModels'
import { StationsSet } from '../components/stations/InternationalStationsSearch'

export const apiServerBaseUrl = process.env.REACT_APP_API_URL
export const apiBaseUrl = `${apiServerBaseUrl}/api`
export const wssBaseUrl = `${apiServerBaseUrl}/wss`

const mapApiUrls: ApiEndpointsState = {
  crwsStations: `${apiBaseUrl}/crws/stations`,
  crwsStationGroups: `${apiBaseUrl}/crws/stations/groups`,
  crwsStation: (id) => `${apiBaseUrl}/crws/stations/${id}`,
  crwsLine: (id) => `${apiBaseUrl}/crws/lines/${id}`,
  crwsLineConnections: (id) => `${apiBaseUrl}/crws/lines/${id}/connections`,
  cisStationDepartures: (id) => `${apiBaseUrl}/crws/stations/${id}/departures`,
  cisStationArrivals: (id) => `${apiBaseUrl}/crws/stations/${id}/arrivals`,
  crwsConnection: (id, ttIndex) => `${apiBaseUrl}/crws/connections/${id}/${ttIndex}`,
  crwsConnectionMapRoute: (id, ttIndex) => `${apiBaseUrl}/crws/connections/${id}/${ttIndex}/route`,

  cisStationsByMask: `${apiBaseUrl}/crws/stations`,

  jdfStations: (jdfDataId) => `${apiBaseUrl}/jdf/${jdfDataId}/stations`,
  jdfStationGroups: (jdfDataId) => `${apiBaseUrl}/jdf/${jdfDataId}/stations/groups`,
  jdfStation: (jdfDataId, stationId) => `${apiBaseUrl}/jdf/${jdfDataId}/stations/${stationId}`,
  jdfLine: (jdfDataId, lineId) =>
    `${apiBaseUrl}/jdf/${jdfDataId}/lines/${lineId.lineNumber}${
      lineId.lineNumberExtension ? `/${lineId.lineNumberExtension}` : ''
    }`,
  jdfLineConnections: (jdfDataId, lineId, stopsAtStationId) =>
    `${apiBaseUrl}/jdf/${jdfDataId}/lines/${lineId.lineNumber}${
      lineId.lineNumberExtension ? `/${lineId.lineNumberExtension}` : ''
    }/connections?stopsAtStationId=${stopsAtStationId}`,
  jdfStationDepartures: (jdfDataId, id) =>
    `${apiBaseUrl}/jdf/${jdfDataId}/stations/${id}/departures`,
  jdfStationArrivals: (jdfDataId, id) => `${apiBaseUrl}/jdf/${jdfDataId}/stations/${id}/arrivals`,
  jdfConnection: (jdfDataId, lineId, connectionId, onlyRealStops) =>
    `${apiBaseUrl}/jdf/${jdfDataId}/lines/${lineId.lineNumber}${
      lineId.lineNumberExtension ? `/${lineId.lineNumberExtension}` : ''
    }/connections/${connectionId}?onlyRealStops=${onlyRealStops}`,
  jdfConnectionMapRoute: (jdfDataId, lineId, connectionId) =>
    `${apiBaseUrl}/jdf/${jdfDataId}/lines/${lineId.lineNumber}${
      lineId.lineNumberExtension ? `/${lineId.lineNumberExtension}` : ''
    }/connections/${connectionId}/route`,

  connections: (id) => `${apiBaseUrl}/tt-maps/connections/${id}`,
}

export const signalRUrls = {
  jdf: `${wssBaseUrl}/jdf`,
  cis: `${wssBaseUrl}/cis`,
}

export const apiUrls = {
  translations: (language: string): string =>
    `/dist/translations/${language}.json?t=${new Date().getTime()}`,
  jdfUploader: {
    load: `${apiBaseUrl}/jdf/uploader/load`,
    save: `${apiBaseUrl}/jdf/uploader/save`,
    remove: `${apiBaseUrl}/jdf/uploader/remove`,
  },
  jdf: {
    lines: {
      list: (jdfDataId: string): string => `${apiBaseUrl}/jdf/${jdfDataId}/lines`,
      route: {
        optimize: (jdfDataId: string, lineNumber: LineNumberExtended): string => {
          const urlBase = `${apiBaseUrl}/jdf/${jdfDataId}/lines/${lineNumber.lineNumber}`
          return lineNumber.lineNumberExtension
            ? urlBase + `/${lineNumber.lineNumberExtension}/route/optimize`
            : urlBase + '/route/optimize'
        },
      },
    },
    protocol: (jdfDataId: string): string => `${apiBaseUrl}/jdf/${jdfDataId}/protocol`,
    exportData: (jdfDataId: string, exportId = ''): string =>
      `${apiBaseUrl}/jdf/${jdfDataId}/export/${exportId}`,
  },
  cis: {
    stations: {
      international: (mask: string, set: StationsSet) =>
        `${apiBaseUrl}/cis/stations/international?listType=${set.toString()}&mask=${mask}`,
    },
  },
  map: mapApiUrls,
  
  auth: {
    register: `${apiBaseUrl}/auth/register`, 
    confirmEmailAddress: `${apiBaseUrl}/auth/confirmEmailAddress`,
    login: `${apiBaseUrl}/auth/login`,
    logout: `${apiBaseUrl}/auth/logout`,
    requestPasswordResetEmail: `${apiBaseUrl}/auth/requestPasswordResetEmail`,
    resetPassword: `${apiBaseUrl}/auth/resetPassword`,
    user: `${apiBaseUrl}/auth/user`,
  },
  
  users: {
    getAll: `${apiBaseUrl}/users`,
    verify: (userId: number) => `${apiBaseUrl}/users/${userId}/verify`,
  },

  registrationAllowedEmails: {
    base: `${apiBaseUrl}/registrationAllowedEmails`,
    getAll: `${apiBaseUrl}/registrationAllowedEmails/all`,
    updateAndDelete: (registrationAllowedEmailId: number) =>
      `${apiUrls.registrationAllowedEmails.base}/${registrationAllowedEmailId}`,
  },
  
  cisCpCredentials: {
    base: `${apiBaseUrl}/cisCpCredentials`,
  },

  cisCpBatches: {
    base: `${apiBaseUrl}/cisCpBatches`,
    performAction: (batchUserName: string, batchSequenceNumber: number) => 
      `${apiUrls.cisCpBatches.base}/${batchUserName}/${batchSequenceNumber}/performAction`,
    complete: (batchUserName: string, batchSequenceNumber: number) =>
      `${apiUrls.cisCpBatches.base}/${batchUserName}/${batchSequenceNumber}/complete`,
  },
}

const jdfUrlPrefix = '/jdf/:jdfDataId'
const adminBase = '/admin'

export const appUrls = {
  home: '/',
  jdf: {
    lines: {
      pattern: `${jdfUrlPrefix}/lines`,
      withParams: (params: JdfRouteParams): string => `/jdf/${params.jdfDataId}/lines`,
    },
    lineDetails: {
      pattern: [
        // Order matters! (More specific route must come first)
        `${jdfUrlPrefix}/lines/:lineNumber/:lineNumberExtension`,
        `${jdfUrlPrefix}/lines/:lineNumber`,
      ],
      withParams: (params: JdfLineDetailsPageRouteParams): string => {
        if (!params.lineNumberExtension)
          return `/jdf/${params.jdfDataId}/lines/${params.lineNumber}`

        return `/jdf/${params.jdfDataId}/lines/${params.lineNumber}/${params.lineNumberExtension}`
      },
    },
    lineEdit: {
      pattern: [
        // Order matters! (More specific route must come first)
        `${jdfUrlPrefix}/lines/:lineNumber/:lineNumberExtension/edit/:whatToEdit`,
        `${jdfUrlPrefix}/lines/:lineNumber/edit/:whatToEdit`,
      ],
      withParams: (params: JdfLineEditPageRouteParams): string => {
        if (!params.lineNumberExtension)
          return `/jdf/${params.jdfDataId}/lines/${params.lineNumber}/edit/${params.whatToEdit}`

        return `/jdf/${params.jdfDataId}/lines/${params.lineNumber}/${params.lineNumberExtension}/edit/${params.whatToEdit}`
      },
    },
    connectionEdit: {
      pattern: [
        // Order matters! (More specific route must come first)
        `${jdfUrlPrefix}/lines/:lineNumber/:lineNumberExtension/connections/:connectionId/edit`,
        `${jdfUrlPrefix}/lines/:lineNumber/connections/:connectionId/edit`,
      ],
      withParams: (params: JdfConnectionEditPageParams): string => {
        if (!params.lineNumberExtension)
          return (
            `/jdf/${params.jdfDataId}/lines/${params.lineNumber}` +
            `/connections/${params.connectionId}/edit`
          )

        return (
          `/jdf/${params.jdfDataId}/lines/${params.lineNumber}/${params.lineNumberExtension}` +
          `/connections/${params.connectionId}/edit`
        )
      },
    },
    providers: {
      pattern: `${jdfUrlPrefix}/providers`,
      withParams: (params: JdfRouteParams): string => `/jdf/${params.jdfDataId}/providers`,
    },
    protocol: {
      pattern: `${jdfUrlPrefix}/protocol`,
      withParams: (params: JdfRouteParams): string => `/jdf/${params.jdfDataId}/protocol`,
    },
    map: {
      pattern: `${jdfUrlPrefix}/map`,
      withParams: (params: MapPageRouteParams): string => {
        let url = `/jdf/${params.jdfDataId}/map`

        if (params.sId && params.sIdType) {
          const queryParams = new URLSearchParams()
          queryParams.append('sId', params.sId)
          queryParams.append('sIdType', params.sIdType)

          url += '?' + queryParams.toString()
        }

        return url
      },
    },
  },
  map: '/map',
  internationalStations: '/stations/international',
  register: '/register',
  emailAddressConfirmation: '/email-address-confirmation',
  login: '/login',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset',
  admin: {
    manageDate: `${adminBase}/manage-data`,
    usersList: `${adminBase}/users`,
    registrationAllowedEmails: `${adminBase}/registrationAllowedEmails`,
  },
  profile: '/profile',
  jdfBatches: '/jdfBatches',
}
