import React, { FC, useState } from 'react'
import { CisCpBatch } from '../../models/CisCpModels'
import { Box, IconButton, Tooltip } from '@mui/material'
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { Localizer } from '../../utils/localizer'
import { CompleteBatchConfirmDialog } from './CompleteBatchConfirmDialog'

interface Props {
  cisCpBatch: CisCpBatch
}

export const CompleteBatchButton: FC<Props> = props => {
  const [completeBatchConfirmDialogOpen, setCompleteBatchConfirmDialogOpen] = useState(false)
  
  const handleClick = () => {
    setCompleteBatchConfirmDialogOpen(true)
  }
  
  return (
    <>
      <Tooltip title={Localizer.localize('Complete batch')}>
        <Box>
          <IconButton
            size={'small'}
            onClick={handleClick}
          >
            <SwitchAccessShortcutIcon />
          </IconButton>
        </Box>
      </Tooltip>    
      
      <CompleteBatchConfirmDialog
        open={completeBatchConfirmDialogOpen}
        handleClose={() => setCompleteBatchConfirmDialogOpen(false)}
        model={props.cisCpBatch}
      />
    </>
  )
}
