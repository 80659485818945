import React, { FC, ReactElement, useState } from 'react'
import { Action, CisCpBatch } from '../../models/CisCpModels'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Localizer } from '../../utils/localizer'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import { capitalizeFirstLetter } from '../../utils/stringUtils'
import { PerformActionWithCisCpBatchConfirmDialog } from './PerformActionWithCisCpBatchConfirmDialog'
import { DisapproveCisCpBatchDialog } from './DisapproveCisCpBatchDialog'

export const getActionIconAndDescription = (action: Action): {
  icon: ReactElement,
  localizedDescription: string,
  noteRequired: boolean
} => {
  switch (action) {
    case Action.Delete:
      return {
        icon: <DeleteRoundedIcon />,
        localizedDescription: Localizer.localize(capitalizeFirstLetter(Action.Delete)),
        noteRequired: false,
      }
    case Action.Submit:
      return {
        icon: <ContentPasteGoRoundedIcon />,
        localizedDescription: Localizer.localize(capitalizeFirstLetter(Action.Submit)),
        noteRequired: false,
      }
    case Action.Approve:
      return {
        icon: <CheckRoundedIcon />,
        localizedDescription: Localizer.localize(capitalizeFirstLetter(Action.Approve)),
        noteRequired: false,
      }
    case Action.Disapprove:
      return {
        icon: <CloseRoundedIcon />,
        localizedDescription: Localizer.localize(capitalizeFirstLetter(Action.Disapprove)),
        noteRequired: true,
      }
    case Action.Forward:
      return {
        icon: <PublishRoundedIcon />,
        localizedDescription: Localizer.localize(capitalizeFirstLetter(Action.Forward)),
        noteRequired: false,
      }
  }
}

interface Props {
  cisCpBatch: CisCpBatch
  action: Action
}

export const ChangeStatusButton: FC<Props> = props => {

  const [confirmActionDialogOpen, setConfirmActionDialogOpen] = useState(false)
  const [disapproveDialogOpen, setDisapproveDialogOpen] = useState(false)

  const { icon, localizedDescription, noteRequired } = getActionIconAndDescription(props.action)
  
  const handleClick = () => {
    if (noteRequired) {
      setDisapproveDialogOpen(true) // // open disapprove dialog
    } else {
      setConfirmActionDialogOpen(true) // open confirm action dialog
    }
  }

  return (
    <>
      <Tooltip title={localizedDescription}>
        <Box>
          <IconButton
            size={'small'}
            onClick={handleClick}
          >
            {icon}
          </IconButton>
        </Box>
      </Tooltip>

      <PerformActionWithCisCpBatchConfirmDialog
        open={confirmActionDialogOpen}
        handleClose={() => setConfirmActionDialogOpen(false)}
        cisCpBatch={props.cisCpBatch}
        action={props.action}
      />
      
      <DisapproveCisCpBatchDialog
        open={disapproveDialogOpen}
        handleClose={() => setDisapproveDialogOpen(false)}
        model={props.cisCpBatch}
      />
    </>
  )
} 
