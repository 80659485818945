import { DateTime } from 'luxon'

export interface LoginViewModel {
  emailAddressOrUserName: string
  password: string
}

export interface LoginDto {
  accessToken: string
  userAuth: UserAuthDto
}

export interface UserAuthDto {
  userId: number
  role: Role
  userName: string
  email: string
  lastName: string | undefined
  firstName: string | undefined
  loginExpires: string
  hasCisCpCredentialsSaved: boolean
}

export type UserAuth = Omit<UserAuthDto, 'loginExpires'> & {
  loginExpires: DateTime
}

// Role names
export enum Role {
  Admin = 'Admin',
  TransportOffice = 'TransportOffice',
  Provider = 'Provider',
}

export interface RegisterViewModel {
  emailAddress: string
  username: string
  password: string
}

export interface ResetPasswordViewModel {
  emailAddress: string
  code: string
  password: string
}

export interface UserExcerptDto {
  id: number
  
  email: string
  userName: string
  
  emailConfirmed: boolean
  
  registeredAt: string
  isVerified: boolean
  verifiedAt: string | null // can be null
  
  // from UserDetail
  firstName: string
  lastName: string
  companyName: string
  
}

export type UserExcerpt = Omit<UserExcerptDto, 'registeredAt' | 'verifiedAt'> & {
  registeredAt: DateTime
  verifiedAt: DateTime | null
}

export interface RegistrationAllowedEmailDto {
  id: number

  emailAddress: string
  roleName: Role
  invitationDateTime: string
  addedByUser: UserExcerptDto
  isAlreadyRegistered: boolean
}

export type RegistrationAllowedEmail = Omit<RegistrationAllowedEmailDto, 'invitationDateTime' | 'addedByUser'> & {
  invitationDateTime: DateTime
  addedByUser: UserExcerpt
}

export interface RegistrationAllowedEmailViewModel {
  emailAddress: string
  roleName: Role
}
