import React, { FC, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useCisCpCredentials } from '../../../data/queryFunctions'
import { Localizer } from '../../../utils/localizer'
import { Preloader } from '@inprop/tt-ui-elements'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CisCpCredentialsDialog from './CisCpCredentialsDialog'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import CisCpCredentialsDeleteConfirmDialog from './CisCpCredentialsDeleteConfirmDialog'

const CisCpCredentials: FC = () => {
  const { data: cisCpCredentials, isLoading, error } = useCisCpCredentials()
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  return (
    <>
      <Stack>
        <div className={'h3'}>{Localizer.localize('CIS CP Credentials')}</div>
        
        {isLoading &&
          <div className={'py-5 w-100 text-center'}>
            <div>{Localizer.localize('Loading')}...</div>
            <Preloader overlay={false} centered />
          </div>
        }
  
        {!isLoading &&
          <>
            {cisCpCredentials &&
              <Typography>
                <span>{Localizer.localize('Username')}: </span>
                <span style={{ fontWeight: 'bold' }}>{cisCpCredentials?.userName}</span>
              </Typography>
            }
            {!cisCpCredentials &&
              <div>
                <Typography className={'mb-1'}>
                  {Localizer.localize('You have not provided Your credentials to CIS CP.')}
                </Typography>
                <Typography className={'mb-1'}>
                  {Localizer.localize('Add CIS CP Credentials by clicking on button below.')}
                </Typography>
              </div>
            }
            
            <Stack
              direction={'row'}
              gap={1}
              className={'mt-2'}
            >
              <Button
                variant={'contained'}
                onClick={() => setAddEditDialogOpen(true)}
                endIcon={cisCpCredentials
                  ? <EditRoundedIcon/>
                  : <AddRoundedIcon/>
                }
              >
                {cisCpCredentials
                  ? Localizer.localize('Edit')
                  : Localizer.localize('Add')}
              </Button>

              {cisCpCredentials &&
                <Button
                  variant={'outlined'}
                  color={'warning'}
                  onClick={() => setDeleteDialogOpen(true)}
                  endIcon={<DeleteRoundedIcon/>}
                >
                  {Localizer.localize('Delete')}
                </Button>
              }
            </Stack>
          </>
        }
      </Stack>
      
      <CisCpCredentialsDialog 
        open={addEditDialogOpen}
        handleClose={() => setAddEditDialogOpen(false)} 
        cisCpCredentials={cisCpCredentials}
      />
      
      <CisCpCredentialsDeleteConfirmDialog 
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
      />
    </>
  )
}

export default CisCpCredentials
