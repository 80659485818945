import React, { FC } from 'react'
import ConfirmDialog, { CustomConfirmDialogProps } from '../../general/ConfirmDialog'
import { Localizer } from '../../../utils/localizer'
import { useDeleteCisCpCredentials } from '../../../data/mutations'
import { Typography } from '@mui/material'
import { useAuthContext } from '../../../contexts/AuthContext'

const CisCpCredentialsDeleteConfirmDialog: FC<CustomConfirmDialogProps> = (props) => {
  const { onHasUserCisCpCredentialsSavedChanged } = useAuthContext()
  
  const {
    mutateAsync: deleteCisCpCredentials,
    isLoading: isDeleting
  } = useDeleteCisCpCredentials()

  const handleDeleteConfirm = async () => {
    await deleteCisCpCredentials()

    onHasUserCisCpCredentialsSavedChanged(false)
    props.handleClose()
  }
  
  return (
    <ConfirmDialog
      open={props.open}
      title={Localizer.localize('Delete')}
      onConfirm={handleDeleteConfirm}
      onClose={props.handleClose}
      isLoading={isDeleting}
      content={
        <Typography>
          {Localizer.localize('Are you sure to delete CIS CP Credentials')}?
        </Typography>
      }
    />
  )
}

export default CisCpCredentialsDeleteConfirmDialog
