import { ReactElement, useContext } from 'react'
import { NotificationsContext, NotificationType } from '../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../contexts/NotificationsReducer'

export const useNotifications = () => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const addNotification = (
    type: NotificationType,
    title: string,
    body?: string | ReactElement | ((closeFn: () => void) => ReactElement),
    autoDismissAfterSeconds?: number,
    disableAutoDismiss?: boolean,
    htmlId?: string,
    onClose?: () => void
  ) =>
    notificationsDispatch({
      type: ADD_NOTIFICATION,
      value: {
        type: type,
        title: title,
        body: body,
        autoDismissAfterSeconds: autoDismissAfterSeconds,
        disableAutoDismiss: disableAutoDismiss,
        htmlId: htmlId,
        onClose: onClose
      }
    })

  const addNeutralNotification = (
    title: string,
    body?: string | ReactElement | ((closeFn: () => void) => ReactElement),
    autoDismissAfterSeconds?: number,
    disableAutoDismiss?: boolean,
    htmlId?: string,
    onClose?: () => void,
  ) => addNotification(NotificationType.Neutral, title, body, autoDismissAfterSeconds,
    disableAutoDismiss, htmlId, onClose)

  const addSuccessNotification = (
    title: string,
    body?: string | ReactElement | ((closeFn: () => void) => ReactElement),
    autoDismissAfterSeconds?: number,
    disableAutoDismiss?: boolean,
    htmlId?: string,
    onClose?: () => void,
  ) => addNotification(NotificationType.Success, title, body, autoDismissAfterSeconds,
    disableAutoDismiss, htmlId, onClose)

  const addErrorNotification = (
    title: string,
    body?: string | ReactElement | ((closeFn: () => void) => ReactElement),
    autoDismissAfterSeconds?: number,
    disableAutoDismiss?: boolean,
    htmlId?: string,
    onClose?: () => void,
  ) => addNotification(NotificationType.Error, title, body, autoDismissAfterSeconds,
    disableAutoDismiss, htmlId, onClose)

  const addWarningNotification = (
    title: string,
    body?: string | ReactElement | ((closeFn: () => void) => ReactElement),
    autoDismissAfterSeconds?: number,
    disableAutoDismiss?: boolean,
    htmlId?: string,
    onClose?: () => void,
  ) => addNotification(NotificationType.Warning, title, body, autoDismissAfterSeconds,
    disableAutoDismiss, htmlId, onClose)
  
  return {
    addNotification,
    addNeutralNotification,
    addSuccessNotification,
    addErrorNotification,
    addWarningNotification,
  }
}
