import React, { FC, useContext } from 'react'
import { CisCpCredentialsViewModel } from '../../../models/ProfileModels'
import { useCreateCisCpCredentials, useUpdateCisCpCredentials } from '../../../data/mutations'
import { Localizer } from '../../../utils/localizer'
import TextInput from '../../general/inputs/TextInput'
import { useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { CisCpCredentials } from '../../../models/ProfileModels'
import { useAuthContext } from '../../../contexts/AuthContext'

interface Props {
  open: boolean
  handleClose: () => void
  cisCpCredentials: CisCpCredentials | undefined
}

const cisCpCredentialsFormId = 'cisCpCredentialsForm'

const CisCpCredentialsDialog: FC<Props> = (props) => {
  const { onHasUserCisCpCredentialsSavedChanged } = useAuthContext()
  
  const { 
    register,
    handleSubmit,
    errors,
  } = useForm<CisCpCredentialsViewModel>()
  
  const { 
    mutateAsync: createCisCpCredentialsAsync,
    isLoading: isCreating
  } = useCreateCisCpCredentials()
  
  const { 
    mutateAsync: updateCisCpCredentialsAsync,
    isLoading: isUpdating
  } = useUpdateCisCpCredentials()

  const addingNew = !props.cisCpCredentials
  const isMutating = isCreating || isUpdating
  
  const onSubmit = async (cisCpCredentialsViewModel: CisCpCredentialsViewModel) => {
    if (addingNew) {
      await createCisCpCredentialsAsync(cisCpCredentialsViewModel)
      onHasUserCisCpCredentialsSavedChanged(true)
    } else {
      await updateCisCpCredentialsAsync(cisCpCredentialsViewModel)
    }

    props.handleClose()
  }
  
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>
        {addingNew
          ? Localizer.localize('Add')
          : Localizer.localize('Edit')
        }
      </DialogTitle>
      
      <DialogContent>
        <form
          id={cisCpCredentialsFormId}
          onSubmit={handleSubmit(onSubmit)}
          className={'mb-0'}
        >
          <div>
            <TextInput
              id={'cisCpUsernameInput'}
              name={'userName'}
              type={'text'}
              label={Localizer.localize('CIS CP username')}
              defaultValue={props.cisCpCredentials?.userName ?? ''}
              ref={register({
                required: Localizer.localize('Please, enter your username')
              })}
              error={errors.userName?.message}
            />
            <TextInput
              id={'cisCpPasswordInput'}
              name={'password'}
              type={'password'}
              label={Localizer.localize('CIS CP password')}
              defaultValue={''}
              ref={register({
                required: Localizer.localize('Please, enter your password')
              })}
              error={errors.password?.message}
            />
          </div>
        </form>
      </DialogContent>
      
      <DialogActions>
        <Button
          onClick={props.handleClose}
          variant={'outlined'}
        >
          {Localizer.localize('Cancel')}
        </Button>
        <LoadingButton
          type={'submit'}
          form={cisCpCredentialsFormId}
          loading={isMutating}
          variant={'contained'}
        >
          {addingNew
            ? Localizer.localize('Add')
            : Localizer.localize('Save changes')
          }
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CisCpCredentialsDialog
