import React, { FC, useContext, useEffect } from 'react'
import CisCpBatchesList from '../cisCpBatches/CisCpBatchesList'
import { LayoutContext, LayoutHeight } from '../../contexts/LayoutContext'
import { SET_LAYOUT_HEIGHT } from '../../contexts/LayoutReducer'
import { Localizer } from '../../utils/localizer'

const CisCpBatchesPage: FC = () => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext)

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.Full })

    return () => layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.ByContent })
  }, [layoutDispatch])
  
  return (
    <div id={'cisCpBatchesContainer'}
         className={'d-flex flex-column flex-grow-1'}>

      <div className={'row pt-2 w-100'}>
        <div className={'col-12 d-flex'}>
          <div className={'h3'}>{Localizer.localize('Batches in CIS CP')}</div>
        </div>
      </div>

      <CisCpBatchesList />
      
    </div>
  )
}

export default CisCpBatchesPage
