import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './queryKeys'
import { getAllRegistrationAllowedEmails, getAllUsers, getCisCpBranches, getCisCpCredentials } from './apiFunctions'
import { CisCpBatch, CisCpStatus } from '../models/CisCpModels'
import { CursorPaginatedResponse } from '../models/GeneralModels'

export const useAllUsers = () =>
  useQuery({
    queryKey: queryKeys.users,
    queryFn: getAllUsers,
  })

export const useAllRegistrationAllowedEmails = () =>
  useQuery({
    queryKey: queryKeys.registrationAllowedEmails,
    queryFn: getAllRegistrationAllowedEmails,
  })

export const useCisCpCredentials = () =>
  useQuery({
    queryKey: queryKeys.cisCpCredentials,
    queryFn: getCisCpCredentials,
  })

export const useCisCpBatches = (nameFilter: string, statusFilter: CisCpStatus[], pageSize: number, pageNumber: number) =>
  useQuery<CursorPaginatedResponse<CisCpBatch[]>, unknown>({
    queryKey: queryKeys.cisCpBatches(pageSize, pageNumber),
    queryFn: () => getCisCpBranches(nameFilter, statusFilter, pageSize, pageNumber),
    retry: false,
    // switch the following prop on to remove default loading animation between pages; but there would 
    // be the need to display some different loading animation for loading next page
    // (for example, in footer of table or so)
    // keepPreviousData: true,
  })

