import { useContext, useEffect, useState } from 'react'
import { JdfContext } from '../../../../contexts/JdfContext'
import { NotificationsContext, NotificationType } from '../../../../contexts/NotificationsContext'
import useAxios from 'axios-hooks'
import { ListLineApi } from '../../../../components/jdf/line/LinesList'
import { apiUrls } from '../../../../utils/urls'
import { parseISO } from 'date-fns'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'
import { Localizer } from '../../../../utils/localizer'
import { LineNumberExtended } from '../../../../models/LineModels'

export type CheckedStatus = 'none' | 'some' | 'all';

const useFetchLinesWithSelection = () => {
  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  // TODO: use useQuery() instead of useAxios and remove as many useEffects as possible
  const [{ data: rawLinesData, loading: isLoadingLines, error: loadingLinesError }, fetchLines] =
    useAxios<ListLineApi<string>[]>({})

  const [lines, setLines] = useState<ListLineApi<Date>[]>([])

  const [linesSelected, setLinesSelected] = useState<{ [index: string]: boolean | undefined }>({})
  const [checkedStatus, setCheckedStatus] = useState<CheckedStatus>('all')

  useEffect(() => {
    if (!jdfState.jdfDataId) {
      return
    }

    fetchLines({ url: apiUrls.jdf.lines.list(jdfState.jdfDataId) })
  }, [jdfState.jdfDataId])

  useEffect(() => {
    if (!rawLinesData) {
      setLines([])

      return
    }

    setLines(
      rawLinesData.map((_) => {
        return {
          ..._,
          lineValidFrom: parseISO(_.lineValidFrom),
          lineValidTo: parseISO(_.lineValidTo),
        }
      })
    )
  }, [rawLinesData])

  const getLineIndex = (lineNumber: LineNumberExtended) =>
    `${lineNumber.lineNumber}${lineNumber.lineNumberExtension}`
  
  const setAllLinesSelected = (selected: boolean) => {
    const newSelectedLines: { [index: string]: boolean } = {}

    for (let i = 0; i < lines.length; i++) {
      newSelectedLines[getLineIndex(lines[i].lineNumber)] = selected
    }

    setLinesSelected(newSelectedLines)
  }

  useEffect(() => {
    setAllLinesSelected(true)
    setCheckedStatus('all')
  }, [lines])

  useEffect(() => {
    let wasFalse = false
    let wasTrue = false

    for (const key in linesSelected) {
      if (linesSelected[key]) {
        wasTrue = true
      } else {
        wasFalse = true
      }
    }

    if (wasFalse && wasTrue) {
      setCheckedStatus('some')
    } else if (wasFalse) {
      setCheckedStatus('none')
    } else {
      setCheckedStatus('all')
    }
  }, [linesSelected])

  useEffect(() => {
    if (loadingLinesError) {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Lines could not be loaded'),
          body: Localizer.localize('Please, try again'),
        },
      })
    }
  }, [loadingLinesError])

  const onLineClick = (lineNumber: LineNumberExtended) => {
    setLinesSelected((_) => {
      const lineIndex = getLineIndex(lineNumber)

      if (!_[lineIndex]) {
        return { ..._, [lineIndex]: true }
      }

      return { ..._, [lineIndex]: false }
    })
  }

  const onBulkToggleClick = () => {
    if (checkedStatus === 'all' || checkedStatus === 'some') {
      setAllLinesSelected(false) // de-select all lines
    } else {
      setAllLinesSelected(true) // select all lines
    }
  }
  
  const getSelectedLines = () => {
    const selectedLines: LineNumberExtended[] = []

    for (const key in lines) {
      if (linesSelected[getLineIndex(lines[key].lineNumber)]) {
        selectedLines.push(lines[key].lineNumber)
      }
    }
    
    return selectedLines
  }
  
  return {
    data: lines,
    loading: isLoadingLines,
    error: loadingLinesError,
    linesSelected,
    checkedStatus,
    onLineClick,
    onBulkToggleClick,
    getLineIndex,
    getSelectedLines,
  }
}

export default useFetchLinesWithSelection
