import React, { FC, useState } from 'react'
import { Alert, Button, Typography } from '@mui/material'
import axios from 'axios'
import { Localizer } from '../../../utils/localizer'
import { errorCodes } from '../../../data/errorCodes'
import { CisCpBatch } from '../../../models/CisCpModels'
import JdfBatchCredentialsErrorAlert from '../../cisCpBatches/JdfBatchCredentialsErrorAlert'
import ProtocolLog from '../../jdf/ProtocolLog'

interface Props {
  error: unknown
  onGoToProfile: () => void
  logMessage: string
}

const UploadJdfBatchErrorAlert: FC<Props> = (props) => {
  const [viewProtocol, setViewProtocol] = useState<boolean>(false)
  
  const error = props.error
  
  if (error && axios.isAxiosError(error) && error.response)
  {
    // here we have axios error with response (AxiosResponse) - containing http status, headers, ...

    if (error.response.data) { // TODO: try to update Axios lib to get axios.isAxiosError<T>(error)
      // here we have standardized error response from BE (CPEditErrorResponse)
      const cpEditErrorResponse = error.response.data
      const code = cpEditErrorResponse.code

      if (code === errorCodes.CisCp_NoCredentialsCreated
        || code === errorCodes.CisCp_WrongCredentials
      ) {
        return (
          <JdfBatchCredentialsErrorAlert
            error={error}
            noCredentialsCreatedWarningMessage={Localizer.localize(
              'To upload JDF batch to CIS CP You have to provide CIS CP credentials.'
            )}
            wrongCredentialsCWarningMessage={Localizer.localize(
              'Upload of JDF batch failed, because provided CIS CP credentials are wrong.'
            )}
            onGoToProfile={props.onGoToProfile}
          />
        )
      }
      
      // if response has following error codes, response status is certainly 400 BadRequest and
      // errorData is filled with CisCpBatch
      if (code === errorCodes.CisCp_BatchImportedWithErrors
        || code === errorCodes.CisCp_BatchImportFinishedInUnexpectedState
      ) {
        const uploadedBatch = cpEditErrorResponse.errorData as CisCpBatch | undefined
        
        const headerText = code === errorCodes.CisCp_BatchImportedWithErrors
          ? Localizer.localize('Imported was finished, but JDF batch contains errors!')
          : Localizer.localize('Imported was finished, but JDF batch is in unexpected state!')
        
        return (
          <Alert severity={'warning'} className={'w-100'}>
            <div>
              <Typography variant={'h6'}>
                {headerText}
              </Typography>
              <Typography>
                {Localizer.localize('See more details in protocol')}.
              </Typography>
              {uploadedBatch &&
                <Typography>
                  <span>{Localizer.localize('Sequence number of uploaded batch')}:</span>{' '}
                  <span className={'font-weight-bold'}>{uploadedBatch.sequenceNumber}</span>
                </Typography>
              }
            </div>
  
            {uploadedBatch && !viewProtocol && (
              <div>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  className={'mt-2'}
                  onClick={() => setViewProtocol(true)}
                >
                  {Localizer.localize('View protocol')}
                </Button>
              </div>
            )}
  
            {uploadedBatch && viewProtocol && (
              <div>
                <Typography className={'mt-2'}>{Localizer.localize('Protocol')}{' '}</Typography>
                <ProtocolLog protocolLog={uploadedBatch.protocol} />
              </div>
            )}
          </Alert>
        )
      }
      
      // other 400 BadRequest - error codes from TIMETABLE_WS_ERROR, errorData is null, message is set
      // and 500 InternalServerError - error code is null, message is set
      const message = cpEditErrorResponse.message

      console.error(props.logMessage + ':', JSON.stringify(cpEditErrorResponse))

      return (
        <Alert severity={'error'} className={'w-100'}>
          <div>
            <p className={'mb-1'}>
              <b>{Localizer.localize('Upload of JDF batch to CIS CP failed.')}</b>
            </p>
            <p className={'mb-3'}>
              {Localizer.localize('Please, try again')}.
              {' '}
              {Localizer.localize('If problem persists, contact us')}.
            </p>
            <p className={'mb-1'}>
              {Localizer.localize('Additional info')}:
            </p>
            {code &&
              <p className={'mb-1'}>
                {Localizer.localize('Error code')}: {code}
              </p>
            }
            <p className={'mb-1'}>
              {Localizer.localize('Error message')}: {message}
            </p>
          </div>

        </Alert>
      )
    }
  }

  console.error(props.logMessage + ':', JSON.stringify(error))
  
  return (
    <Alert severity={'error'}>
      <Typography>{Localizer.localize('Unknown error occurred')}</Typography>
    </Alert>
  )
}

export default UploadJdfBatchErrorAlert
