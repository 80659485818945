import React, { FC, useContext, useState } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { JdfContext } from '../../../contexts/JdfContext'
import useFetchLinesWithSelection from '../../../hooks/data/jdf/line/useFetchLinesWithSelection'
import { useUploadBatchToCisCp } from '../../../data/mutations'
import { Localizer } from '../../../utils/localizer'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { NavigationContext } from '../../../contexts/NavigationContext'
import { UploadBatchToCisCpViewModel } from '../../../models/CisCpModels'
import JdfLinesSelector from '../../jdf/JdfLinesSelector'
import TextInput from '../../general/inputs/TextInput'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { Button as TtUiElementsButton, ButtonColor, ButtonSize } from '@inprop/tt-ui-elements'
import LoadingButton from '@mui/lab/LoadingButton'
import UploadJdfBatchErrorAlert from './UploadJdfBatchErrorAlert'
import ProtocolLog from '../../jdf/ProtocolLog'

interface FormData {
  note: string;
}

const UploadJdfBatchToCisCpButton: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  const {
    state: { onNavigateElsewhere },
  } = useContext(NavigationContext)
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [viewProtocol, setViewProtocol] = useState<boolean>(false)
  
  const {
    register,
    handleSubmit,
    errors,
  } = useForm<FormData>()
  
  const {
    data: lines,
    loading: isLoadingLines,
    getSelectedLines,
    error: loadingLinesError,
    ...fetchLinesWithSelection
  } = useFetchLinesWithSelection()

  const {
    mutateAsync: uploadBatchToCisCpAsync,
    isLoading: isUploading,
    data: uploadedBatch,
    error,
    status: uploadingStatus,
    reset: resetUploadBatchToCisCp,
  } = useUploadBatchToCisCp()

  const handleButtonOnClick = () => {
    resetUploadBatchToCisCp()
    
    if (onNavigateElsewhere) {
      onNavigateElsewhere(() => setDialogOpen(true))
      
      return
    }

    setDialogOpen(true)
  }
  
  const uploadJdfBatch = async (data: FormData) => {
    if (!jdfState.jdfDataId) {
      throw Error("jdfDataId must be set in jdfState to upload the data to CIS CP")
    }

    const selectedLines = getSelectedLines()
    if (selectedLines.length === 0){
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Please select at least one route to be uploaded to CIS CP'),
          type: NotificationType.Warning,
        }
      })
      
      return
    }
    
    const viewModel: UploadBatchToCisCpViewModel = {
      jdfDataId: jdfState.jdfDataId,
      linesToInclude: selectedLines,
      note: data.note,
    }
    
    uploadBatchToCisCpAsync(viewModel)
  }
  
  const handleClose = () => {
    setDialogOpen(false)
    setViewProtocol(false)
  }
  
  return (
    <>
      <TtUiElementsButton
        color={ButtonColor.TransparentDark}
        size={ButtonSize.Small}
        onClick={handleButtonOnClick}
      >
        <FontAwesomeIcon icon={faUpload} className={'mr-2'} /> {Localizer.localize('Upload')}
      </TtUiElementsButton>

      <Dialog open={dialogOpen} maxWidth={'md'} fullWidth onClose={handleClose}>
        <DialogTitle>{Localizer.localize('Upload JDF batch to CIS CP')}</DialogTitle>
        <DialogContent>
          {(uploadingStatus === 'idle' || uploadingStatus === 'loading') && (
            <Grid container>
              <JdfLinesSelector
                lines={lines}
                isLoadingLines={isLoadingLines}
                loadingLinesError={loadingLinesError}
                noLinesInfoMessage={Localizer.localize(
                  'Your JDF data does not contain any lines and therefore cannot be uploaded to CIS CP'
                )}
                {...fetchLinesWithSelection}
              />

              <Grid item xs={12}>
                <TextInput
                  id={'noteInput'}
                  name={'note'}
                  type={'text'}
                  label={Localizer.localize('Description')}
                  ref={register({
                    required: Localizer.localize('Please enter description of JDF batch'),
                  })}
                  error={errors.note?.message}
                  disabled={isLoadingLines || lines.length === 0}
                />
              </Grid>
            </Grid>
          )}

          {/*{uploadingStatus === 'loading' && (*/}
          {/* TODO: improve loading animation */}
          {/*)}*/}

          {uploadingStatus === 'success' && (
            <Alert severity={'success'}>
              <div>
                <Typography variant={'h6'}>
                  {Localizer.localize('JDF batch was successfully uploaded to CIS CP')}
                </Typography>
                <Typography>{Localizer.localize('See more details in protocol')}.</Typography>
                {uploadedBatch && (
                  <Typography>
                    <span>{Localizer.localize('Sequence number of uploaded batch')}:</span>{' '}
                    <span className={'font-weight-bold'}>{uploadedBatch.sequenceNumber}</span>
                  </Typography>
                )}
              </div>

              {uploadedBatch && !viewProtocol && (
                <div>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mt-2'}
                    onClick={() => setViewProtocol(true)}
                  >
                    {Localizer.localize('View protocol')}
                  </Button>
                </div>
              )}

              {uploadedBatch && viewProtocol && (
                <div>
                  <Typography className={'mt-2'}>{Localizer.localize('Protocol')} </Typography>
                  <ProtocolLog protocolLog={uploadedBatch.protocol} />
                </div>
              )}
            </Alert>
          )}

          {uploadingStatus === 'error' && (
            <UploadJdfBatchErrorAlert
              error={error}
              logMessage={'Error during upload of JDF batch to CIS CP'}
              onGoToProfile={handleClose}
            />
          )}
        </DialogContent>

        <DialogActions className={'px-4 pb-3_5'}>
          {(uploadingStatus == 'idle' || uploadingStatus == 'loading') && (
            <LoadingButton
              variant={'contained'}
              onClick={handleSubmit(uploadJdfBatch)}
              className={'mr-1'}
              loading={isUploading}
              disabled={isLoadingLines || lines.length === 0}
            >
              {Localizer.localize('Upload to CIS CP')}
            </LoadingButton>
          )}
          <Button onClick={handleClose} color={'secondary'}>
            {Localizer.localize('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UploadJdfBatchToCisCpButton
