import { DateTime } from 'luxon'
import { LineNumberExtended } from './LineModels'

export interface CisCpBatchDto {
  /**
   * Name of the user who uploaded this batch.
   * Not displayed in separate column in table in CIS CP.
   */
  userName: string

  /**
   * Number of this batch for given user.
   * Not displayed in separate column in table in CIS CP.
   */
  sequenceNumber: number

  /**
   * Custom text defined when uploading the batch.
   * Corresponds to "Popis" column in table in CIS CP.
   */
  description: string

  /**
   * Status of this batch.
   */
  batchStatus: CisCpStatus

  /**
   * Date and time of upload (import).
   * Send from BE in local (Slovakia, Czechia) timezone (CET - UTC+1/+2), not in UTC!
   * Corresponds to "Nahraté" column in table in CIS CP.
   */
  uploadedAt: string

  /**
   * Whole content of import protocol.
   * Filled only when loading info about single batch, not present when loading list of batches.
   */
  protocol: string

  /**
   * Routes of this batch.
   * Empty if this batch does not contain any routes.
   */
  routes: CisCpRouteExcerptDto[]

  /**
   * Array of actions that can be performed with this batch by user who loaded it.
   * Empty if there are no actions to perform with this batch.
   */
  actions: Action[]
}

export type CisCpBatch = Omit<CisCpBatchDto, 'uploadedAt' | 'routes'> & {
  uploadedAt: DateTime
  routes: CisCpRouteExcerpt[]
}

/**
 * Excerpt of JdfBatchRoute.
 * Used to display the list of lines in the tooltip.
 * 
 */
export interface CisCpRouteExcerptDto {

  /**
   * Number of the route as displayed in "Linky" column in table in CIS CP.
   */
  routeNumber: number 
}

export type CisCpRouteExcerpt = CisCpRouteExcerptDto

/**
 * Represents the status of batch OR line in CIS CP.
 */
export enum CisCpStatus {
  None = 'none',
  Processing = 'processing',
  Error = 'error',
  Uploaded = 'uploaded',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Forwarded = 'forwarded',
  Submitted = 'submitted',
  Processed = 'processed'
}

export interface UploadBatchToCisCpViewModel {
  jdfDataId: string;
  linesToInclude: LineNumberExtended[];
  note: string;
}

/**
 * Represents an action that can be performed with batch or line in CIS CP.
 * Corresponds to BATCH_LINE_ACTION enum from TimetableWs.
 */
export enum Action {
  Delete = "delete",
  Submit = "submit",
  Approve = "approve",
  Disapprove = "disapprove",
  Forward = "forward"
}
