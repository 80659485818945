import React, { FC, ReactElement } from 'react'
import { JdfKonExitCode, JdfProtocolApiModel } from '../../models/protocol'
import { Localizer } from '../../utils/localizer'
import ProtocolLog from './ProtocolLog'

const JdfProtocol: FC<JdfProtocolApiModel> = (props) => {
  const getJdfConExitCodeDescription = (): ReactElement => {
    switch (props.jdfKonExitCode) {
      case JdfKonExitCode.Ok:
        return <span className={'text-success'}>{Localizer.localize('Data is ok')}</span>
      case JdfKonExitCode.Warn:
        return (
          <span className={'text-warning'}>
            {Localizer.localize('Data is not quite all right')}
          </span>
        )
      case JdfKonExitCode.Error:
      case JdfKonExitCode.FatalError:
        return <span className={'text-danger'}>{Localizer.localize('Data is not usable')}</span>
    }
  }

  const getColorByUnknown = (allCount: number, unknownCount: number): string => {
    if (unknownCount === 0) {
      return 'text-success'
    }

    if (unknownCount !== allCount) {
      return 'text-warning'
    }

    return 'text-danger'
  }

  return (
    <div className={'py-2'}>
      {props.errorText && <div className={'text-danger'}>{props.errorText}</div>}
      <div>{getJdfConExitCodeDescription()}</div>
      
      {props.stationsCount !== 0 && (
        <div className={getColorByUnknown(props.stationsCount, props.unknownStationsCount)}>
          <span>
            {Localizer.localize('Unknown stations')}: {props.unknownStationsCount}
          </span>{' '}
          <span>
            ({Localizer.localize('overall')}: {props.stationsCount})
          </span>
        </div>
      )}

      {props.providersCount !== 0 && (
        <div className={getColorByUnknown(props.providersCount, props.unknownProvidersCount)}>
          <span>
            {Localizer.localize('Unknown providers')}: {props.unknownProvidersCount}
          </span>{' '}
          <span>
            ({Localizer.localize('overall')}: {props.providersCount})
          </span>
        </div>
      )}
      
      <ProtocolLog protocolLog={props.log} />
    </div>
  )
}

export default JdfProtocol