import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Action, CisCpBatch } from '../../models/CisCpModels'
import { StandardDialogProps } from '../../utils/DialogUtils'
import { usePerformActionWithCisCpBatch } from '../../data/mutations'
import { useForm } from 'react-hook-form'
import { Localizer } from '../../utils/localizer'
import TextInput from '../general/inputs/TextInput'
import LoadingButton from '@mui/lab/LoadingButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import useId from '@mui/material/utils/useId'
import { getKeyOf } from '../../utils/keyUtils'

interface DisapproveFormData {
  reason: string
}

export const DisapproveCisCpBatchDialog: FC<StandardDialogProps<CisCpBatch>> = (props) => {
  
  const { register, errors, handleSubmit } = useForm<DisapproveFormData>()

  const {
    mutateAsync: performActionWithCisCpBatch,
    isLoading
  } = usePerformActionWithCisCpBatch()

  const disapproveCisCpBatchFormId = 'disapproveCisCpBatchForm_' + useId()
  const disapprovalReasonTextInputId = 'disapprovalReasonTextInput_' + useId()

  const onSubmit = async (formData: DisapproveFormData) => {
    await performActionWithCisCpBatch({
      batchUserName: props.model.userName,
      batchSequenceNumber: props.model.sequenceNumber,
      action: Action.Disapprove,
      note: formData.reason,
    })
    
    props.handleClose()
  }
  
  return (
    <Dialog 
      open={props.open}
      onClose={props.handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>
        {Localizer.localize('Batch disapproval')}
      </DialogTitle>
      
      <DialogContent>
        <form
          id={disapproveCisCpBatchFormId}
          onSubmit={handleSubmit(onSubmit)}
          className={'mb-0'}
        >
          <div>
            <TextInput 
              id={disapprovalReasonTextInputId}
              name={getKeyOf<DisapproveFormData>('reason')}
              type={'text'}
              label={Localizer.localize('Reason of disapproval')}
              ref={register({
                required: Localizer.localize('Please, enter a reason of disapproval')
              })}
              error={errors.reason?.message}
            />
          </div>
        </form>
      </DialogContent>
        
      <DialogActions>
        <LoadingButton
          form={disapproveCisCpBatchFormId}
          loading={isLoading}
          type={'submit'}
          variant={'contained'}
          startIcon={<CloseRoundedIcon />}
        >
          {Localizer.localize('Disapprove')}
        </LoadingButton>
        <Button 
          onClick={props.handleClose}
          color={'secondary'}
        >
          {Localizer.localize('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
