import React, { FC, useContext, useState } from 'react'
import { Button, ButtonColor, ButtonSize } from '@inprop/tt-ui-elements'
import { faSave } from '@fortawesome/free-regular-svg-icons/faSave'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Localizer } from '../../../utils/localizer'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import JdfLinesSelector from '../../jdf/JdfLinesSelector'
import { NavigationContext } from '../../../contexts/NavigationContext'
import {navigationGuide} from "../../guide/content/navigation";
import { ExportJdfCreateDto } from '../../../models/jdf/export'
import axios from 'axios'
import { apiUrls } from '../../../utils/urls'
import useAxios from 'axios-hooks'
import { JdfContext } from '../../../contexts/JdfContext'
import useFetchLinesWithSelection from '../../../hooks/data/jdf/line/useFetchLinesWithSelection'

const SaveJdfButton: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  const {
    state: { onNavigateElsewhere },
  } = useContext(NavigationContext)
  
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const {
    data: lines,
    loading: isLoadingLines,
    error: loadingLinesError,
    getSelectedLines,
    ...fetchLinesWithSelection
  } = useFetchLinesWithSelection()
  
  const [{}, exportJdfData] = useAxios<string>(
    { url: apiUrls.jdf.exportData(jdfState.jdfDataId ?? ''), method: 'POST' },
    { manual: true }
  )
  
  const onClick = () => {
    if (onNavigateElsewhere) {
      onNavigateElsewhere(() => setShowDialog(true))

      return
    }

    setShowDialog(true)
  }

  const saveJdfBatch = async () => {
    if (!jdfState.jdfDataId) {
      throw new Error('JDF data must be set when exporting data')
    }

    const requestBody: ExportJdfCreateDto = {
      linesToInclude: getSelectedLines(),
    }

    const exportedJdfDataId = (
      await exportJdfData({
        data: requestBody,
      })
    ).data

    const link = document.createElement('a')
    link.href = axios.getUri({
      url: apiUrls.jdf.exportData(jdfState.jdfDataId ?? '', exportedJdfDataId),
    })
    link.click()

    onClose()
  }
  
  const onClose = () => setShowDialog(false)

  return (
    <>
      <Button
        id={navigationGuide.save.target}
        color={ButtonColor.TransparentDark}
        size={ButtonSize.Small}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faSave} className={'mr-2'} /> {Localizer.localize('Save')}
      </Button>

      <Dialog open={showDialog} maxWidth={'md'} fullWidth onClose={() => setShowDialog(false)}>
        <DialogTitle>{Localizer.localize('Save JDF')}</DialogTitle>
        <DialogContent>
          <Grid container>
            <JdfLinesSelector
              lines={lines}
              isLoadingLines={isLoadingLines}
              loadingLinesError={loadingLinesError}
              noLinesInfoMessage={Localizer.localize(
                'Your JDF data does not contain any lines and therefore cannot be exported'
              )}
              {...fetchLinesWithSelection}
            />

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              {!isLoadingLines && lines.length !== 0 && (
                <Button
                  size={ButtonSize.Small}
                  onClick={saveJdfBatch}
                  className={'mr-1'}
                  disabled={lines.length === 0}
                >
                  {Localizer.localize('Save')}
                </Button>
              )}
              <Button size={ButtonSize.Small} color={ButtonColor.Light} onClick={onClose}>
                {Localizer.localize('Close')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SaveJdfButton
