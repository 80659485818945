
export const queryKeysPrefixes = {
  users: 'users',
  cisCpBatches: 'cisCpBatches',
}

// Keys for cache
export const queryKeys = {
  users: [queryKeysPrefixes.users],
  user: (id: string) => [queryKeysPrefixes.users, id],
  registrationAllowedEmails: ['registrationAllowedEmails'],
  cisCpCredentials: ['cisCpCredentials'],
  cisCpBatches: (pageSize: number, pageNumber: number) => [queryKeysPrefixes.cisCpBatches, pageSize, pageNumber],
}
